/* Variables */
:root {
  --bg-grey: #e9e9e9;
  --black: #000000;
  --brown: #8a754e;
  --white: #ffffff;
}

/* Elements */
a {
  color: var(--black);
  font-size: large;
  font-weight: 400;
  text-decoration: underline;

  &:hover {
      color: var(--brown);
      transition-duration: 0.5s;
  }
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  margin: unset;
}

hr {
  margin: 1rem auto;
  width: 25%;
}

svg {
  vertical-align: bottom;
}

/* Classes */
.align-center {
  text-align: center;
}

/* ID's */
#contactFormDiv {
  border: none;
  margin-bottom: 1rem;
  padding: 0.5rem 0;
  width: 75%;
}

#root {
  all: unset;
}

#searchBtn {
  display: inline;
  vertical-align: middle;
}

#searchText {
  display: inline;
  vertical-align: middle;
}

/* Media Control */
/* Mobile Friendliness */
@media screen and (max-width: 769px) {
  .inline-small {
    display: inline-block!important;
    padding-inline: 0.5rem;
  }

  .mobile-pad-inline-10 {
    padding-inline: 1rem;
  }

  #contactFormDiv {
    width: 95%;
  }
}
